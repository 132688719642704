.filters-container {
    display: flex;
    flex-direction: column;
    padding: 20px;
    border-radius: 8px;
    background-color: rgb(50, 50, 50);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    width: 250px; /* Możesz dostosować szerokość */
}
.tekstfiltry{
    color: white;
    text-align: center;
    margin-bottom: 10px;
}
.filters-container input,
.filters-container select,
.filters-container button {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-sizing: border-box; /* Dodano box-sizing */
}

.filters-container button {
    background-color: rgb(60, 60, 60);
    color: white;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.filters-container button:hover {
    background-color: rgba(80, 80, 80, 0.85);
}
