.homecont {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.center-content {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

@media (min-width: 600px) and (max-width: 900px) {
  .homecont {
    padding: 15px;
  }
}

@media (max-width: 599px) {
  .homecont {
    padding: 10px;
  }

  .center-content {
    max-width: 100%;
  }
}
