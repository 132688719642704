.ofirmie, .coSerwis {
    text-align: center;
    line-height: 1.6; 
    padding: 0 5%;
}

@media (max-width: 768px) { 
    .ofirmie, .coSerwis {
        margin-right: 10%;
        margin-left: 10%;
        font-size: 95%; 
    }
}

@media (max-width: 480px) { 
    .ofirmie, .coSerwis {
        margin-right: 5%;
        margin-left: 5%;
        font-size: 90%; 
    }
}
h1, h2, h3 {
    margin-top: 20px; 
    margin-bottom: 10px;
}

@media (max-width: 768px) {
    h1 {
        font-size: 1.5rem; 
    }
    h2 {
        font-size: 1.3rem; 
    }
    h3 {
        font-size: 1.1rem;
    }
}

@media (max-width: 480px) {
    h1 {
        font-size: 1.3rem;
    }
    h2 {
        font-size: 1.2rem;
    }
    h3 {
        font-size: 1rem;
    }
}
