.user-orders-container {
    background-color: rgb(50, 50, 50);
    padding: 20px;
    min-height: 80vh;
    margin-top: 120px;
    color: rgb(255, 255, 255);
    border-radius: 10px;

}

.order-card {
    color: rgb(0, 0, 0);
    
    background-color: #ffffff;
    border: 1px solid rgb(80, 80, 80);
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 10px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    position: relative;


}
.order-card::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 5px; /* Wysokość dolnego paska */
    background: linear-gradient(to right, rgb(255, 0, 0), transparent); /* Gradient od intensywnego czerwonego po prawej do przezroczystego po lewej */

  }
  
.order-card h3 {
    color: black;
}

.order-item-list {
    list-style-type: none;
    padding: 0;
    
}

.order-item {
    background-color: #f9f9f9;
    border-bottom: 1px solid #eee;
    padding: 10px;
    display: flex;
    align-items: center;
}

.order-item img {
    width: 100px;
    height: 100px;
    margin-right: 15px;
    border-radius: 5px;
}

.order-item-details {
    flex-grow: 1;
}

/* Możesz dostosować więcej stylów według potrzeb */
