.MachineContainer {
    background-color: rgb(255, 255, 255);
    margin-top: 80px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    gap: 40px 50px; 
    justify-content: center; 
    margin-left: 2%;
    margin-right: 2%;
    
}

.maszrol{
    background-color: #fdfafa;;
    cursor: pointer;
    position:relative;
    border-bottom: 2px solid rgb(255, 0, 0);
    
    width: 100%;
    border-radius: 10px;
    margin-bottom: 1px
}
.MachineItem:hover > img,
.maszrol:hover > img {
    transform: scale(1.3);
    transition: transform 0.5s; 
}

.MachineItem:hover > div,
.maszrol:hover > div {
    transform: scale(0.9);
    transition: transform 0.5s; 
}
.MachineImageRol{
    max-height: 45%;
    min-height: 41%;
    width: 70%;
    margin-left: 30px;
    justify-content: center;
}

.Opis{
    
    display: grid;
    flex-wrap: wrap;
    min-height: 300px;
    max-height: 100%;
    border: none;
    border-radius: 5%;
    background-color: rgb(80,80,80);
    grid-template-columns: 170px 120px;
    grid-row-gap: 0px;
    justify-content: space-between;
    font-weight: 100;
    border-radius: 3px solid rgb(24, 175, 13);
    margin-top: 5px;
    color:rgb(255, 255, 255);
    font-size: 18px;



}

.Opis>h5{
    text-align: start;
}
.Opis>p{
    font-size: medium;
    text-align: end;
    
    
}
.OpisMaszyn{
    font-size: 20px;
    color: rgba(0, 0, 0, 0.600);
    text-align: right;
    position: absolute;
    bottom: 59%; 
    right: 20px; 
    display: inline-block; 
    border-bottom: 5px solid rgb(255, 0, 0);
    border-radius: 5px;
}
.maszrol:hover>h7{
    opacity: 0.5;
    transition-duration: 0.5s;
}
a {
    text-decoration: none; 
  }
  
.maszrol:hover>img{
    transform: scale(1.4);
    transition-duration: 0.5s;
}
.maszrol:hover>div{
   opacity: 0.5;
   transition-duration: 0.5s;
}
@media (max-width: 768px) {
    .MachineContainer {
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); 
    }

}

@media (max-width: 480px) {
    .MachineContainer {
        grid-template-columns: 1fr; 
        gap: 10px; 
    }

    .MachineImageRol {
        width: 90%; /* Adjust image width for mobile */
        margin-left: 5%; /* Center the image within its container */
    }


    .OpisMaszyn{
        font-size: 20px;
        color:rgba(0, 0, 0, 0.600);;
        text-align: right;
        position: absolute;
        top: 0;
        bottom: 15%;
        left: 0;
        right: 20px;
        height: min-content;
        margin: auto;
    
        
    }
}