.navbar {
  z-index: 5;
  height: 75px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  background-color: rgb(50, 50, 50);
  position: fixed;
  top: 0;
  border-bottom: solid 1px var(--primary-color);
  opacity: 0.9;
  margin-bottom: 5px;
}

.logos {
  display: flex;
  height: 100%;
  /* Usunięto margin-left: 0%; aby uniknąć niepotrzebnej przestrzeni */
}

.logo {
  height: 100%;
  margin-left: 10%;
}

.navItems {
  display: flex;
  justify-content: flex-end; /* Aby elementy były wyrównane do prawej */
  align-items: center; /* Dla pionowego wyrównania */
  flex-grow: 1; /* Pozwala na rozciągnięcie do dostępnej przestrzeni */
}

.b1, .b2, .b3, .b4, .b5 {
  background-color:  rgb(50, 50, 50);
  color: white;
  font-size: 20px;
  padding: 5px 15px;
  border: 0;
  cursor: pointer;
  margin-left: 10px; /* Dodano margines dla oddzielenia przycisków */
}

.b1:hover, .b2:hover, .b3:hover, .b4:hover, .b5:hover {
  box-shadow: 0 0 5px rgba(0,0,0,0.5);
  border-radius: 10px;
}
/* Responsive adjustments */
@media (max-width: 768px) {
  .logo {
    margin-left: 5%; /* Less space on the sides for smaller screens */
    height: 80%; /* Smaller logo on tablets */
  }

  .b1, .b2, .b3, .b4, .b5 {
    font-size: 18px; /* Smaller font size for nav items */
    padding: 5px 10px; /* Smaller padding */
    margin-left: 5px; /* Less margin between buttons */
  }
}

@media (max-width: 480px) {
  .navbar {
    justify-content: space-between; 
  }

  .logo {
    height: 100%;
    margin-left: 2%; 
  }



}