.all {
  margin-top: 120px;
}

.shop {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.shop-content {
  display: flex;
  flex-direction: row;
  align-items: start;
  width: 100%;
}

.filters {
  margin-top: 100px;
  width: 20%;
  position: sticky;
  top: 120px;
  margin-left: 10px;
}

.content {
  width: 80%;
}

.text-center {
  text-align: center;
}

.product-grid {
  margin-right: 100px;
  background-color: rgb(50, 50, 50);
  display: grid;
  grid-template-columns: repeat(auto-fill, 270px);
  grid-gap: 90px 90px;
  justify-content: space-evenly;
  border-radius: 4px;
}

