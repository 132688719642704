
.opiss, .opisdwa {
    margin:  0; 
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    font-family: Arial, Helvetica, sans-serif;
    padding: 20px;
    line-height: 1.6;
    text-align: center;
}
.ofirmie>{

    font-family: Arial;
    text-align:center;
    text-overflow: clip;
    color: grey;
}
.coSerwis>{
    position: relative;
    text-align: center;
    color: gray;
}
.SerwisRight>{
    position: relative;
    
    text-align: left;
}
.SerwisCenter{
    text-align: center;    
}