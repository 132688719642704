/* Default styles for mobile */
.stopa {
    background-color: rgb(50, 50, 50);
    color: rgb(150, 150, 150); /* Text color for all child elements unless overridden */
    padding: 2rem; /* Padding for all sides */
    text-align: center; /* Center text for mobile */
}

/* Icon links style */
.icon-link {
    display: flex;
    align-items: center;
    justify-content: center; /* Center icons with text for mobile */
    gap: 0.5rem; /* Space between icon and text */
    color: inherit; /* Inherits the color from .stopa */
}

/* This removes the underline from links and ensures visited links have the same style */
.stopa a, .stopa a:visited { 
    color: inherit; /* Inherits color from .stopa */
    text-decoration: none; /* Removes the underline */
}

/* Media query for desktops and larger screens */
@media (min-width: 768px) { 
    .stopa {
        text-align: left; /* Align text to the left on desktop */
        /* Optional: Add more desktop-specific styles here */
    }
    .icon-link {
        justify-content: flex-start; /* Align icons with text to the left on desktop */
    }
}
