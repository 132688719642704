.image-carousel-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: auto;
  margin-top: 5%;
  padding: 20px 0;
}

.custom-carousel-root {
  width: 100%;
  max-width: 1000px; /* Increase max-width to make images larger */
}

.carousel .slide {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 600px; /* Increase height to make images larger */
}

.carousel .slide img {
  max-width: 80%;
  max-height: 80%;
  object-fit: contain;
}
.custom-carousel-root {
  width: 75% !important;
  max-width: 1000px !important;
}

.carousel .slide {
  height: 500px !important;
}

.carousel .slide img {
  max-width: 100% !important;
  max-height: 100% !important;
  object-fit: contain !important;
}
