.product-card {
    
    background-color: #ffffff;
    cursor: pointer;
    position: relative;
    border: 1px solid rgb(80, 80, 80);
    border-bottom: 2px solid rgb(255, 0, 0);
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 10px;
    height: 350px;
    width: 250px; 
    
  }
  

  
  .product-card:hover .product-body {
    transform: scale(1.2);
    transition-duration: 0.5s;
  }
  
  .product-img {
    width: 100%; /* Obraz zajmuje całą szerokość kontenera */
    height: 250px; /* Stała wysokość dla każdego obrazu produktu */
    object-fit: cover; /* Obcięcie i skalowanie obrazu, aby pasował do przestrzeni zachowując proporcje */
    border-radius: 8px; /* Zaokrąglenie rogów obrazu */
  }
  
  .product-body {
    padding: 15px;
    text-align: center;
    height: 150px; 
  }
  .product-item {
    margin: 15px; 
    margin-right: 15px;
  }
  