/* Ogólne style dla kontenera formularza */
.user-profile-container {
    
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #f2f2f2;
    border-radius: 8px;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
    margin: 20px;
    
}

/* Styl dla nagłówka profilu użytkownika */
.user-profile-header {
    margin-top: 70px;
    font-size: 24px;
    color: #333;
    margin-bottom: 20px;
    
}

/* Styl dla formularza */
.user-profile-form {
    
    width: 100%;
    max-width: 500px;
    padding: 15px;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0px 5px 15px rgb(255, 255, 255);
    background-color: rgb(50, 50, 50);

}

/* Styl dla etykiet i pól formularza */
.user-profile-form label {
    
    display: block;
    margin-bottom: 5px;
    font-size: 16px;
    color: #ffffff;
}

.user-profile-form input[type="text"],
.user-profile-form input[type="email"] {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-sizing: border-box; /* Aby szerokość inputów uwzględniała padding */
}

/* Styl dla przycisków */
.user-profile-form button {
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    background-color: rgb(60, 60, 60);
    color: white;
    cursor: pointer;
    transition: background-color 0.3s;
}

.user-profile-form button:hover {
    background-color: rgba(80, 80, 80, 0.85);
}

/* Styl dla linków w profilu użytkownika */
.user-profile-link {
    background-color: rgba(80, 80, 80, 0.85);
    text-decoration: none;
    margin-top: 10px;
    display: inline-block;
}

.user-profile-link:hover {
    text-decoration: underline;
}

/* Styl dla komunikatów o błędach lub powiadomień */
.error-message,
.success-message {
    padding: 10px;
    margin-top: 10px;
    border-radius: 4px;
    color: white;
    text-align: center;
}

.error-message {
    background-color: #dc3545;
}

.success-message {
    background-color: #28a745;
}
