
  
  .logos2 {
    display: flex;
    height: 100%;
  }
  
  .logo2 {
    height: 100%;
    margin-left: 10%; /* Styl zgodny z .logo w pierwszym pasku */
  }
  
  .navContainer2 {
    z-index: 5;
    height: 75px;
    display: flex;
    justify-content: space-around; /* Zmieniono z 'center' na 'space-around' */
    align-items: center;
    width: 100%;
    background-color: rgb(50, 50, 50);
    position: fixed;
    top: 0;
    border-bottom: solid 1px var(--primary-color);
    opacity: 0.9;
  }
  

  
  .ainv2 {
    display: flex;
    justify-content: flex-end; /* Aby elementy były wyrównane do prawej */
    align-items: center; /* Dla pionowego wyrównania */
    flex-grow: 1; /* Pozwala na rozciągnięcie do dostępnej przestrzeni */

  }
  
  .nav-item {
    display: flex;
    align-items: center;
    margin-right: 10%;
  }
  .b9 {
    background-color:  rgb(50, 50, 50);
    color: white;
    font-size: 20px;
    padding: 5px 15px;
    border: 0;
    cursor: pointer;
    margin-left: 10px; /* Dodano margines dla oddzielenia przycisków */
  }
  .b9:hover {
    box-shadow: 0 0 5px rgba(0,0,0,0.5);
    border-radius: 10px;
  }
  