.order-form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #f8f8f8;
    border-radius: 8px;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
    margin: 20px;
}

.order-form-header {
    font-size: 24px;
    color: #333;
    margin-bottom: 20px;
}
.metplatnosc{
    display: flex;
    color: white;
    flex-direction: column;
    align-items: center;
    text-align: center;
    height: 100px;
}
.order-form {
    width: 100%;
    max-width: 400px;
    padding: 15px;
    background-color: #323232;
    border-radius: 5px;
    box-shadow: 0px 5px 15px rgba(0,0,0,0.05);
}

.order-form-input {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-sizing: border-box;
}

.order-form-submit {
    width: 100%;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    background-color: rgb(60, 60, 60);
    color: white;
    cursor: pointer;
    transition: background-color 0.3s;
}

.order-form-submit:hover {
    background-color: rgba(80, 80, 80, 0.85);
}
