.product-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1280px;
  margin: auto;
  padding: 20px;
  font-family: 'Roboto Condensed', sans-serif;
  color: #000;
  background-color: #fff;
}

.product-images-info {

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  margin-top: 85px;
}

.carousel-root {
  max-width: 50%;
  margin-right: 20px;
}

.carousel .carousel-slider .slider-wrapper.axis-horizontal {
  width: 100%;
  height: 100%; /* Ensure the height is 100% to fit the container */
}

.carousel .slide {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 500px; /* Set a specific height for the carousel */
}

.carousel .slide img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain; /* Ensure the image is contained within the container without being cropped */
}

.product-info {
  flex: 1;
  margin-left: 20px;
}

.product-info > h2 {
  color: #e20714;
  margin: 0;
  padding: 5px 0;
  font-weight: 700;
  font-style: italic;
}

.product-info h1, .product-info h3 {
  color: rgb(0, 0, 0);
  margin: 0;
  padding: 5px 0;
  font-weight: 700;
  font-style: italic;
}

.info-table {
  border-collapse: collapse;
  width: 100%;
  margin-top: 15px;
}

.info-table th, .info-table td {
  padding: 12px;
  border: 1px solid #ddd;
  font-size: 1em;
}

.info-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.product-description {
  margin-top: 20px;
}

.product-description p {
  margin: 0;
}

.product-description .see-more {
  display: block;
  margin-top: 10px;
  color: #e20714;
  text-decoration: none;
  cursor: pointer;
  background: none;
  border: none;
  font: inherit;
  outline: inherit;
}

.product-buttons {
  margin-top: 20px;
}

.product-buttons .btn {
  display: block;
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  text-align: center;
  background-color: #e20714;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.product-buttons .btn-secondary {
  background-color: #4a4a4a;
}

.product-buttons .btn:hover {
  background-color: #d00414;
}

.product-buttons .btn-secondary:hover {
  background-color: #333;
}

.header-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #e20714;
  font-family: 'Roboto Condensed', sans-serif;
  margin: 40px 0;
  width: 100%;
}

.header-container .main-header {
  font-size: 2em;
  font-weight: bold;
}

.arrow {
  color: #e20714;
  font-size: 2em;
  margin-top: 10px;
}

.product-specifications {
  width: 100%;
  max-width: 999px;
  margin-top: 20px;
  margin-bottom: 0;
  border-radius: 10px;
  padding: 20px;
  color: #ffffff;
  background-color: #323232;
}

.accordion-section {
  margin: auto;
  border: 1px solid #e20714;
  border-radius: 10px;
  width: 80%;
  margin-bottom: 10px;
  box-shadow: 0 6px 6px 0 #e20714;
  background-color: #e20714;
}

.accordion-title {
  width: 100%;
  text-align: left;
  padding: 15px;
  cursor: pointer;
  border: none;
  background: #323232;
  border-bottom: 1px solid #ff0000;
  border-radius: 10px;
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 1.1em;
  color: #ffffff;
}

.accordion-content {
  display: none;
  padding: 15px;
  font-size: 1em;
  background: #454545;
  color: #ffffff;
}

.accordion-title:hover {
  transition: background-color 0.3s;
  background-color: #555555;
}

.accordion-section.is-open .accordion-content {
  display: block;
}

.table-container {
  padding: 0 10%;
}

.specs-table {
  width: 100%;
  border-collapse: collapse;
  margin: 10px 0;
}

.specs-table td {
  padding: 8px;
  border-bottom: 1px solid #ccc;
}

.specs-table tr:not(:last-child) td {
  border-bottom: 1px solid #eaeaea;
}

.download-section {
  margin-top: 20px;
  text-align: center;
}

.download-button {
  padding: 10px 20px;
  background-color: #e20714;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
}

.download-button:hover {
  background-color: #a00000;
}

@media (max-width: 768px) {
  .product-images-info {
    flex-direction: column;
    align-items: center;
  }

  .carousel-root {
    max-width: 100%;
    margin-right: 0;
    height: auto; /* Allow the height to adjust for smaller screens */
  }

  .product-info {
    margin-left: 0;
    margin-top: 20px;
  }
}
