.List {
    background-color: rgb(50, 50, 50);
    min-width: 80%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr)); 
    gap: 90px 70px;
    justify-content: center; 
}

.MachineItem {
    background-color: #ffffff;
    margin-top: 10px;
    cursor: pointer;
    border: 1px solid rgb(80, 80, 80);
    border-bottom: 2px solid rgb(255, 0, 0);
    border-radius: 10px;
    margin-left: 5%;
    margin-right: 5%;
    margin-bottom: 2%;
}

  
.MachineImage {
    width: 100%;
    height: auto;
    transition: transform 0.5s ease-in-out;
    
}

.MachineItem:hover > img {
    transform: scale(1.3); 
}

.MachineItem:hover > .MachineTitle {
    transform: scale(0.9); 
}

.MachineTitle > h1 {
    text-align: center;
    color: black;
    font-size: 18px;
    padding: 10px; 
}
@media(min-width: 768px) {
    .List {
        grid-template-columns: repeat(2, 1fr); 
    }
}

@media (min-width: 1024px) {
    .List {
        grid-template-columns: repeat(3, 1fr); 
    }
}

